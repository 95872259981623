import React from 'react';
import { Helmet } from 'react-helmet';

import SEO from '../components/seo';
import { Section } from '../components/page-elements';
import Container from '../components/container';
import CenteredHeaderBlock from '../components/centeredHeaderBlock';
import BottomCut from '../components/bottomCut';

const HeadingText = {
  preHeader: 'Thank you!',
  title: 'Form Submitted Successfully',
  body: 'Still looking for more?',
  primaryCTA: true,
  primaryCTAData: {
    label: 'Take me home, country roads',
    type: 'primary',
    arrow: true,
    path: '/',
  },
  secondaryCTA: false,
  secondaryCTAData: {
    label: 'Let me talk to someone',
    type: 'secondary',
    arrow: true,
    path: '/contact',
  },
};

const Success = () => (
  <>
    <SEO title='Success' />
    <Helmet>
      <body className='light-nav' />
    </Helmet>
    <Section light cuts='bottomCut' style={{ minHeight: '80vh' }}>
      <Container>
        <CenteredHeaderBlock data={HeadingText} />
      </Container>
      <BottomCut dark />
    </Section>
  </>
);

export default Success;
